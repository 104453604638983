export default {
  product: {
    rows: [{
      id: 1,
      cat_id: 1,
      code: 'B124',
      name: 'บุษปวัน',
      status: 1,
      imgs: '',
      brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
      detail: '',
      color: '-',
      size: 'M',
      tags: ['ลิลลี่'],
      // mapping_cost
      cost: '2000',
      supplier_id: 2,
      cost_lasted: Math.floor(Math.random() * (1000 - 300 + 1)) + 300
    }, {
      id: 2,
      cat_id: 2,
      code: 'A215',
      name: 'กุหลาบ',
      status: 0,
      imgs: '',
      brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
      detail: '',
      color: 'แดง',
      size: 'L',
      tags: [],
      // mapping_cost
      cost: '1500',
      supplier_id: 1,
      cost_lasted: Math.floor(Math.random() * (1000 - 300 + 1)) + 300
    }, {
      id: 3,
      cat_id: 2,
      code: 'A0XX',
      name: 'พวงหรีดดอกไม้สด',
      status: 0,
      imgs: '',
      brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
      detail: '',
      color: 'แดง',
      size: 'L',
      tags: [],
      // mapping_cost
      cost: '1500',
      supplier_id: 1,
      cost_lasted: Math.floor(Math.random() * (1000 - 300 + 1)) + 300
    }, {
      id: 4,
      cat_id: 2,
      code: 'B0XX',
      name: 'พวงหรีดพัดลม',
      status: 0,
      imgs: '',
      brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
      detail: '',
      color: 'แดง',
      size: 'L',
      tags: [],
      // mapping_cost
      cost: '1500',
      supplier_id: 1,
      cost_lasted: Math.floor(Math.random() * (1000 - 300 + 1)) + 300
    }, {
      id: 5,
      cat_id: 2,
      code: 'C0XX',
      name: 'พวงหรีดผ้า',
      status: 0,
      imgs: '',
      brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
      detail: '',
      color: 'แดง',
      size: 'L',
      tags: [],
      // mapping_cost
      cost: '1500',
      supplier_id: 1,
      cost_lasted: Math.floor(Math.random() * (1000 - 300 + 1)) + 300
    }, {
      id: 6,
      cat_id: 1,
      code: 'D0XX',
      name: 'พวงหรีดต้นไม้',
      status: 1,
      imgs: '',
      brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
      detail: '',
      color: '-',
      size: 'M',
      tags: ['ลิลลี่'],
      // mapping_cost
      cost: '2000',
      supplier_id: 2,
      cost_lasted: Math.floor(Math.random() * (1000 - 300 + 1)) + 300
    }, {
      id: 7,
      cat_id: 2,
      code: 'E0XX',
      name: 'พวงหรีดนาฬิกา',
      status: 0,
      imgs: '',
      brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
      detail: '',
      color: 'แดง',
      size: 'L',
      tags: [],
      // mapping_cost
      cost: '1500',
      supplier_id: 1,
      cost_lasted: Math.floor(Math.random() * (1000 - 300 + 1)) + 300
    }, {
      id: 8,
      cat_id: 2,
      code: 'F0XX',
      name: 'พวงหรีดดอกไม้ประดิษฐ์',
      status: 0,
      imgs: '',
      brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
      detail: '',
      color: 'แดง',
      size: 'L',
      tags: [],
      // mapping_cost
      cost: '1500',
      supplier_id: 1,
      cost_lasted: Math.floor(Math.random() * (1000 - 300 + 1)) + 300
    }, {
      id: 9,
      cat_id: 2,
      code: 'FN10x',
      name: 'จัดดอกไม้หน้าศพ',
      status: 0,
      imgs: '',
      brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
      detail: '',
      color: 'แดง',
      size: 'L',
      tags: [],
      // mapping_cost
      cost: '1500',
      supplier_id: 1,
      cost_lasted: Math.floor(Math.random() * (1000 - 300 + 1)) + 300
    }, {
      id: 10,
      cat_id: 2,
      code: 'A001',
      name: 'พวงหรีดดอกไม้สด',
      status: 0,
      imgs: '',
      brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
      detail: '',
      color: 'แดง',
      size: 'L',
      tags: [],
      // mapping_cost
      cost: '1500',
      supplier_id: 1,
      cost_lasted: Math.floor(Math.random() * (1000 - 300 + 1)) + 300
    }],
    // rows: [{
    //   id: 1,
    //   cat_id: 1,
    //   code: 'B124',
    //   name: 'บุษปวัน',
    //   status: 1,
    //   imgs:  [],
    //   brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
    //   detail: '',
    //   color: '-',
    //   size: 'M',
    //   tags: ['ลิลลี่'],
    //   suppliers: [{
    //     id: 1,
    //     name: 'บริษัท ดอกไม้',
    //     tax_id: '12121212',
    //     tax_name: 'นาย A',
    //     tax_address: '',
    //     created: '',
    //     updated: '',
    //     contact: 'นายเก่ง',
    //     phone: '081-633-0101',
    //     email: 'flower@gmail.com',
    //     line: '@flower',
    //     address_id: '',
    //     full_address: '',
    //     remark: '-',
    //     status: ''
    //   }],
    //   stores: [{
    //     id: 1,
    //     name: 'หรีด ณ วัด',
    //     website: 'https://www.wreathnawat.com/',
    //     created: '',
    //     updated: '',
    //     address: '121/232 ชั้น 2',
    //     phone: '0211212121',
    //     email: 'mail@mail.com',
    //     facebook: '@facebook',
    //     instagram: '@instagram',
    //     line: '@line'
    //   }]
    // }, {
    //   id: 2,
    //   cat_id: 2,
    //   code: 'A215',
    //   name: 'กุหลาบ',
    //   status: 0,
    //   imgs:  [],
    //   brief: 'เธอเป็นพวงหรีดดอกไม้สด โทนสี สดใส เช่น สีชมพู, สีเหลือง ขนาดกลาง จัดเป็นรูปทรงกลม ขนาดเส้นผ่านศูนย์กลางประมาณ 80-90 เซนติเมตร ใช้โทนหวาน ได้จากดอกเยอบีร่าชมพู คาร์เนชั่นเหลือง และใช้เบญจมาศขาวแซมทั่วพวง หากได้นำไปร่วมงาน จะช่วยทำให้ผู้พบเห็นรู้สึกดีขึ้นค่ะมั่นใจว่าเจ้าภาพต้องต้องปลาบปลื้มใจที่ได้เห็นพวงหรีดของคุณแน่นอนค่ะ',
    //   detail: '',
    //   color: 'แดง',
    //   size: 'L',
    //   tags: [],
    //   suppliers: [{
    //     id: 2,
    //     name: 'บ้านสวน',
    //     tax_id: '4545454',
    //     tax_name: 'นาย A',
    //     tax_address: '',
    //     created: '',
    //     updated: '',
    //     contact: 'ทองแดง',
    //     phone: '081-010-0101',
    //     email: 'fxdd@gmail.com',
    //     line: '@flower34',
    //     address_id: '',
    //     full_address: '',
    //     remark: 'อยู่หลังดอนเมือง',
    //     status: ''
    //   }],
    //   stores: [{
    //     id: 5,
    //     name: 'หรีดแทนบุญ',
    //     website: 'https://www.wreathnawat.com/',
    //     created: '',
    //     updated: '',
    //     address: '121/232 ชั้น 2',
    //     phone: '0211212121',
    //     email: 'mail@mail.com',
    //     facebook: '@facebook',
    //     instagram: '@instagram',
    //     line: '@line'
    //   }]
    // }],
    paging: {
      page: 1,
      rowTotal: 2
    }
  },
  status: [{
    id: 0,
    name: 'Active'
  }, {
    id: 1,
    name: 'Inactive'
  }],
  categories: [{
    id: 1,
    name: '',
    type: '',
    detail: ''
  }],
  types: [{
    id: 1,
    name: 'สินค้า'
  }, {
    id: 2,
    name: 'ค่าบริการ'
  }, {
    id: 3,
    name: 'อื่นๆ'
  }, , {
    id: 4,
    name: 'ส่วนลด/ส่วนเพิ่ม'
  }],
  supplier: {
    rows: [{
      id: 1,
      name: 'บริษัท ดอกไม้',
      tax_id: '12121212',
      tax_name: 'นาย A',
      tax_address: '',
      created: '',
      updated: '',
      contact: 'นายเก่ง',
      phone: '081-633-0101',
      email: 'flower@gmail.com',
      line: '@flower',
      address_id: '',
      full_address: '',
      remark: '-',
      status: ''
    }, {
      id: 2,
      name: 'บ้านสวน',
      tax_id: '4545454',
      tax_name: 'นาย A',
      tax_address: '',
      created: '',
      updated: '',
      contact: 'ทองแดง',
      phone: '081-010-0101',
      email: 'fxdd@gmail.com',
      line: '@flower34',
      address_id: '',
      full_address: '',
      remark: 'อยู่หลังดอนเมือง',
      status: ''
    }],
    paging: {
      rowTotal: 1
    }
  },
  store: {
    rows: [{
      id: 1,
      name: 'หรีด ณ วัด',
      website: 'https://www.wreathnawat.com/',
      created: '',
      updated: '',
      address: '121/232 ชั้น 2',
      phone: '0211212121',
      email: 'mail@mail.com',
      facebook: '@facebook',
      instagram: '@instagram',
      line: '@line'
    }, {
      id: 2,
      name: 'หรีดมาลา',
      website: 'https://www.wreathnawat.com/',
      created: '',
      updated: '',
      address: '121/232 ชั้น 2',
      phone: '0211212121',
      email: 'mail@mail.com',
      facebook: '@facebook',
      instagram: '@instagram',
      line: '@line'
    }, {
      id: 3,
      name: 'หรีดสเตชั่น',
      website: 'https://www.wreathnawat.com/',
      created: '',
      updated: '',
      address: '121/232 ชั้น 2',
      phone: '0211212121',
      email: 'mail@mail.com',
      facebook: '@facebook',
      instagram: '@instagram',
      line: '@line'
    }, {
      id: 4,
      name: 'เลอหรีด',
      website: 'https://www.wreathnawat.com/',
      created: '',
      updated: '',
      address: '121/232 ชั้น 2',
      phone: '0211212121',
      email: 'mail@mail.com',
      facebook: '@facebook',
      instagram: '@instagram',
      line: '@line'
    }, {
      id: 5,
      name: 'หรีดแทนบุญ',
      website: 'https://www.wreathnawat.com/',
      created: '',
      updated: '',
      address: '121/232 ชั้น 2',
      phone: '0211212121',
      email: 'mail@mail.com',
      facebook: '@facebook',
      instagram: '@instagram',
      line: '@line'
    }, {
      id: 6,
      name: 'Love You Flower',
      website: 'https://www.wreathnawat.com/',
      created: '',
      updated: '',
      address: '121/232 ชั้น 2',
      phone: '0211212121',
      email: 'mail@mail.com',
      facebook: '@facebook',
      instagram: '@instagram',
      line: '@line'
    }, {
      id: 7,
      name: 'A Flower Room',
      website: 'https://www.wreathnawat.com/',
      created: '',
      updated: '',
      address: '121/232 ชั้น 2',
      phone: '0211212121',
      email: 'mail@mail.com',
      facebook: '@facebook',
      instagram: '@instagram',
      line: '@line'
    }, {
      id: 8,
      name: 'Frunt n Flora',
      website: 'https://www.wreathnawat.com/',
      created: '',
      updated: '',
      address: '121/232 ชั้น 2',
      phone: '0211212121',
      email: 'mail@mail.com',
      facebook: '@facebook',
      instagram: '@instagram',
      line: '@line'
    }],
    paging: {
      rowTotal: 1
    }
  },
  search_filters: {
    code_products: [{
      filter_count: 0,
      id: 1,
      name: '0001'
    }, {
      filter_count: 0,
      id: 2,
      name: '002'
    }],
    name_products: [{
      filter_count: 0,
      id: 1,
      name: 'ดอกไม้'
    }, {
      filter_count: 0,
      id: 2,
      name: 'Flower Pot'
    }],
    size_products: [{
      filter_count: 0,
      id: 1,
      name: 'กลาง'
    }, {
      filter_count: 0,
      id: 2,
      name: '8 นิ้ว'
    }],
    color_products: [{
      filter_count: 0,
      id: 1,
      name: 'ขาว'
    }, {
      filter_count: 0,
      id: 2,
      name: 'ฟ้า'
    }],
    tag_filters: [{
      filter_count: 0,
      id: 1,
      name: 'สีขาว'
    }, {
      filter_count: 0,
      id: 2,
      name: 'สีดำ'
    }]
  }
}
