<template lang="">
  <div>
    <h6 class="heading-small text-muted mb-4">ข้อมูลสินค้า</h6>
    <div class="pl-lg-4">
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-4">
              <div class="mb-4">
                <label class="form-label">ประเภทสินค้า</label>
                <Select2 v-model="item.cat_id" :options="categories" :settings="{allowClear: true}" placeholder="ประเภทสินค้า"/>
                <base-input name="product_category" v-model="item.cat_id" class="custom-input-valid-datepicker" :rules="{required: true}"></base-input>
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-4">
                <label class="form-label">รหัสสินค้า</label>
                <base-input name="code" placeholder="รหัสสินค้า" v-model="item.code" :rules="{required: true}" ></base-input>
                <b-form-text id="input-live-help">ไม่ผูกกับร้าน กำหนดชื่อใหม่ภายหลัง</b-form-text>
              </div>
            </div>
            <div class="col-md-4">
              <div class="mb-4">
                <label class="form-label">ชื่อสินค้า</label>
                <base-input name="name" placeholder="ชื่อสินค้า" v-model="item.name" :rules="{required: true}"></base-input>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="mb-4">
            <label class="form-label">คำอธิบายอย่างย่อ</label>
            <b-form-textarea name="brief" placeholder="คำอธิบายอย่างย่อ" v-model="item.brief" rows="3" max-rows="5"></b-form-textarea>
            <base-input name="brief" v-model="item.brief" class="custom-input-valid-datepicker" :rules="{required: true}"></base-input>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="mb-4">
            <label class="form-label optional">รายละเอียด</label>
            <!-- <b-form-textarea name="detail" placeholder="รายละเอียด" v-model="item.detail" rows="3" max-rows="5"></b-form-textarea> -->
            <quill-editor :content="item.detail"
                          :options="editorOption"
                          @change="onEditorChange($event)" />
          </div>
        </div>
        <div class="col-sm-12">
          <div class="row">
            <!-- <div class="col-sm-4">
              <div class="mb-4">
                <label class="form-label">สถานะสินค้า</label>
                <select class="form-control"
                        v-model="item.status">
                  <option v-for="(data) in status"
                          :key="data.id"
                          :value="data.id">
                    {{data.name}}
                  </option>
                </select>
              </div>
            </div> -->
            <div class="col-lg-4 col-md-6">
              <div class="mb-4">
                <label class="form-label optional">สี</label>
                <base-input name="color" placeholder="สี" v-model="item.color"></base-input>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="mb-4">
                <label class="form-label optional">ขนาด</label>
                <base-input name="color" placeholder="ขนาด" v-model="item.size"></base-input>
              </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="mb-4">
                <label class="form-label optional">แท็กสินค้า</label>
                <Select2 v-model="item.tags" :options="tags" :settings="{allowClear: true, multiple: true, tags: true}" placeholder="แท็กสินค้า" @select="selectedTags($event)"/>
                <!-- <base-input name="tags" v-model="tagData" class="custom-input-valid-datepicker" :rules="{required: true}"></base-input> -->
                <!-- <b-form-tags  input-id="tags-basic "
                              v-model="item.tags"
                              tag-class="tags_align_center"
                              placeholder="แท็กสินค้า"
                              :rules="{required: true}"
                              name="tags"
                              autocomplete="off">
                </b-form-tags> -->
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="mb-4">
            <label class="form-label optional" style="margin-right: 30px;">รูปสินค้า <span class="text-xs text-danger">(กดที่รูปภาพเพื่อเปลี่ยนรูป)</span></label>
             <!-- <div>
             <img  :src="funcs.isImgEmpty(item.imgs_encode?item.imgs_encode:displayImg)"
                    class="img-thumbnail"
                    style="max-width: 180px;"
                    @click="uploadFileHandler()">
            </div> -->
          </div>
          <div class="card">
            <div  class="card-body m-auto"
                  style="height: 230px; overflow: auto;">
              <input  type="file"
                      id="fileUpload"
                      accept="image/*"
                      style="display: none"
                      @change="uploadFileChangeHandler"
                      multiple
                      name="imgs[]"/>
              <div v-for="(img, index) in displayImgs" class="position-relative float-left" :key="`display_image_${index}`">
                <i class="fa fa-times text-danger position-absolute" style="right: 3px; top: 3px;" @click="deleteImageHandler(index)"></i>
                <img :key="`product_images_${index}`"
                      :src="img"
                      class="img-thumbnail"
                      style="max-width: 180px;"
                      @click="uploadFileHandler()">
              </div>
              <img  v-if="displayImgs.length === 0"
                    :src="noImageData"
                    class="img-thumbnail"
                    style="max-width: 180px;"
                    @click="uploadFileHandler()">
            </div>
          </div>
        </div>
        <div class="col-sm-12 padding_top_20" v-if="action=='edit'">
          <b-tabs >
            <b-tab class="pt-4" title="ข้อมูลซัพพลายเออร์" active>
              <!-- <b-card-text>
                <tab-supplier :supplierMappingProduct="item.suppliers"
                              :funcs="funcs"
                              @data-rows="getDataRows"/>
              </b-card-text> -->
              <div>
                <div class="pl-lg-4">
                  <b-button size="md" variant="primary" @click="onOpenSupplierHandler" style="width: 155px;"><i class="fa fa-plus"></i> เพิ่มซัพพลายเออร์</b-button>
                  <div class="table-responsive padding_top_20">
                    <table class="table align-middle table-bordered">
                      <thead>
                        <tr class="text-center" >
                          <th style="width: 50px;">ลบ</th>
                          <th style="width: 20%;">ชื่อซัพพลายเออร์</th>
                          <th style="width: 20%;">หมายเลขผู้เสีภาษี</th>
                          <th style="width: 35%;">ชื่อผู้ติดต่อ</th>
                          <th>ราคาต้นทุน</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(data, index) in suppliers" :key="`product_supplier_selected${index}`">
                          <td class="text-center checkbox_style" >
                            <button type="button"
                                    class="btn btn-danger btn-sm"
                                    @click="onDeleteSelectSupplierHandler(index)">
                              <i class="fa fa-times" aria-hidden="true"></i>
                            </button>
                          </td>
                          <td>{{data.name}}</td>
                          <td>{{data.tax_id}}</td>
                          <td>{{data.contact}}</td>
                          <td>
                            <base-input :name="`cost_${data.id}`" placeholder="ราคาต้นทุน" v-model="data.cost" v-decimal-format inputClasses="form-control-sm" :rules="{required: true}"></base-input>
                          </td>
                        </tr>
                        <tr v-if="suppliers.length === 0" class="text-center">
                          <td colspan="5">ไม่มีข้อมูล</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <hr class="my-4">
                </div>
                <dialogAddSupplier @selectedSupplier="onSelectedSupplierHandler" ref="dialogAddSupplier"/>
              </div>
            </b-tab>
            <b-tab class="pt-4" title="ข้อมูลร้านค้า">
              <div>
                <div class="pl-lg-4">
                  <b-button size="md" variant="primary" @click="onOpenStoreHandler" style="width: 125px;"><i class="fa fa-plus"></i> เพิ่มร้านค้า</b-button>
                  <div class="table-responsive padding_top_20">
                    <table class="table align-middle table-bordered">
                      <thead>
                        <tr class="text-center" >
                          <th style="width: 50px;">ลบ</th>
                          <th>ชื่อร้านค้า</th>
                          <th style="width: 25%;">รหัสสินค้า</th><!-- รหัสร้านค้า -->
                          <th style="width: 25%;">ชื่อสินค้า</th><!-- ชื่อร้านค้า -->
                          <th>ราคาขาย</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(data, index) in stores" :key="`product_store_selected${index}`">
                          <td class="text-center checkbox_style" >
                            <button type="button" class="btn btn-danger btn-sm" @click="onDeleteSelectStoreHandler(data)">
                              <i class="fa fa-times" aria-hidden="true"></i>
                            </button>
                          </td>
                          <td>{{data.name}}</td>
                          <td>
                            <base-input :name="`code_${data.id}`" placeholder="รหัสสินค้า" v-model="data.code" inputClasses="form-control-sm" :rules="{required: true}"></base-input>
                          </td>
                          <td>
                            <base-input :name="`product_name_${data.id}`" placeholder="ชื่อสินค้า" v-model="data.product_name" inputClasses="form-control-sm" :rules="{required: true}"></base-input>
                          </td>
                          <td>
                            <base-input :name="`price_${data.id}`" placeholder="ราคาขาย" v-model="data.price" v-decimal-format inputClasses="form-control-sm" :rules="{required: true}"></base-input>
                          </td>
                        </tr>
                        <tr v-if="stores.length === 0" class="text-center">
                          <td colspan="5">ไม่มีข้อมูล</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <hr class="my-4">
                </div>
                <dialogAddStore @selectedStore="onSelectedStoreHandler" ref="dialogAddStore"/>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
      <hr class="my-4">
    </div>
  </div>
</template>
<script>
import $ from 'jquery';
import dialogAddStore from './dialog.add.store';
import dialogAddSupplier from './dialog.add.supplier.vue';
import Constants from '../../../util/constants';

export default {
  name: 'product-form-product',
  data () {
    return {
      noImageData: Constants.noImageData,
      status: [],
      displayImg: '',
      vm: this,
      content: '',
      editorOption: {
        // Some Quill options...
      },
      categories: [],
      imgs: [],
      displayImgs: [],
      tagData: ""
    }
  },
  props: [
    'item', 'tags', 'funcs', 'images', 'tag', 'action', 'stores', 'suppliers', 'imageDeletes'
  ],
  methods: {
    uploadFileHandler(){
      $("#fileUpload").trigger("click");
    },
    uploadFileChangeHandler (event) {
      let img = event.target.files
      if (img.length > 0) {
        const vm = this;
        for (let i = 0; i < img.length; i++) {
          var reader = new FileReader();
          reader.onload = function(event) {
            vm.displayImgs.push(event.target.result);
          }
          reader.readAsDataURL(img[i]);
          vm.item.imgs.push(img[i])
        }

        vm.imgs = img;
      } else {
        this.displayImg = null;
        this.file = null;
        this.imgs = [];
        this.displayImgs = [];
      }
    },
    deleteImageHandler(index){
      const image = this.item.imgs[index];
      if(image.id){
        this.imageDeletes.push(image.id);
        if(image.id_t){
          this.item.deletes.push(image.id_t);
        }
      }
      this.displayImgs.splice(index, 1);
      this.item.imgs.splice(index, 1);
    },
    getDataRows (dataRows) {
      this.$emit('selected-supplier', dataRows);
    },
    getDataRowsStore (dataRows) {
      this.$emit('selected-store', dataRows);
    },
    onEditorChange({ quill, html, text }) {
      this.item.detail = html
    },
    selectedTags({id, text}){
      if(this.item.tags.length>0){
        this.tagData = "a";
      }else{
        this.tagData = "";
      }
    },

    /************ Store Tab ************/
    onOpenStoreHandler(){
      this.$refs.dialogAddStore.getListItems();
      this.$bvModal.show('dialog_add_store');
    },
    onSelectedStoreHandler(s){
      const st = JSON.parse(JSON.stringify(this.stores));
      for(const v of s){
        const storeId = v.id;
        let flagInsert = true;
        for(const vv of st){
          if(vv.id==storeId){
            flagInsert = false;
          }
        }
        if(flagInsert){
          const p = JSON.parse(JSON.stringify(this.item));
          v.product_name = p.name;
          v.code = p.code;
          const data = JSON.parse(JSON.stringify(v));
          this.stores.push(data);
        }
      }
    },
    onDeleteSelectStoreHandler(index){
      this.stores.splice(index, 1);
    },
    /************ Store Tab ************/

    /************ Supplier Tab ************/
    onOpenSupplierHandler(){
      this.$refs.dialogAddSupplier.getListItems();
      this.$bvModal.show('dialog_add_supplier');
    },
    onSelectedSupplierHandler(s){
      const st = JSON.parse(JSON.stringify(this.suppliers));
      for(const v of s){
        const storeId = v.id;
        let flagInsert = true;
        for(const vv of st){
          if(vv.id==storeId){
            flagInsert = false;
          }
        }
        if(flagInsert){
            this.suppliers.push(v);
        }
      }
    },
    onDeleteSelectSupplierHandler(index){
      this.suppliers.splice(index, 1);
    },
    /************ Supplier Tab ************/
  },
  async mounted() {
    this.categories = await this.HttpServices.getMasterData(`/master/getProductCategories`);
  },
  watch: {
    'images' (newVal, oldVal) {
      if (newVal) {
        this.imgs = newVal;
        this.displayImgs = newVal;
      }
    },
    'tag' (newVal, oldVal) {
      if(this.item.tags.length>0){
        this.tagData = "a";
      }else{
        this.tagData = "";
      }
    }
  },
  components: {
    dialogAddStore,
    dialogAddSupplier
  }
}
</script>
